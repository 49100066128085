"use strict";

// global const
const CLASS_HIDDEN = "js-hidden";
const CLASS_DISABLE_SCROLL = "js-disableScroll";
const CLASS_FADE_IN = "js-fadeIn";
const CLASS_FADE_OUT = "js-fadeOut";

// ========================
// switch header class (top page)
(() => {
  const $header = document.getElementById("js-header");
  const class_transparent = "is-transparent";

  const removeClassHeader = () => {
    if ($header == null) {
      return;
    }
    if (document.scrollingElement.scrollTop > 0) {
      $header.classList.remove(class_transparent);
    } else {
      if (!$header.classList.contains(class_transparent)) {
        $header.classList.add(class_transparent);
      }
    }
  };

  window.addEventListener("scroll", () => {
    removeClassHeader();
  });
})();
// ========================

// ========================
// set AOS
(() => {
  // eslint-disable-next-line no-undef
  AOS.init({
    offset: 100,
    duration: 500,
    easing: "ease-out",
  });
})();
// ========================

// ========================
// set flatpickr
(() => {
  let date = new Date();
  let tomorrow = date.setDate(date.getDate() + 1);
  // eslint-disable-next-line no-undef
  flatpickr("#js-flatpickr", {
    locale: "ja",
    altInput: true,
    altFormat: "Y年n月j日（D）",
    dateFormat: "Ymd",
    mode: "range",
    minDate: tomorrow,
  });
})();
// ========================

// ========================
// switch tab
(() => {
  const $tabNav = document.querySelectorAll(".js-tabNav");
  const $tabCont = document.querySelectorAll(".js-tabCont");
  const class_selected = "is-selected";

  const switchTab = (e) => {
    e.preventDefault();
    const $this = e.target;
    const $thisHref = $this.getAttribute("href").replace("#", "");
    const $thisCont = document.getElementById($thisHref);
    if (!$this.classList.contains("is-selected")) {
      let i = 0;
      while (i < $tabNav.length) {
        $tabNav[i].classList.remove(class_selected);
        $tabCont[i].classList.add(CLASS_HIDDEN);
        i++;
      }
      $thisCont.classList.remove(CLASS_HIDDEN);
      $thisCont.classList.add(CLASS_FADE_IN);
      $this.classList.add(class_selected);
    }
  };

  let i = 0;
  while (i < $tabNav.length) {
    $tabNav[i].addEventListener("click", (e) => switchTab(e), false);
    i++;
  }
  // }
})();
// ========================

// ========================
// modal
(() => {
  const $modal = document.getElementById("js-modal");
  const $form = document.getElementById("js-form");
  const $openBtn = document.getElementById("reservationBtn");
  const $closeBtn = document.getElementById("js-closeBtn");
  let state = 0; // 0:not processing, 1:opening. 2: closing

  $openBtn.addEventListener("click", () => {
    if (state == 0) {
      state = 1;
      document.body.classList.add(CLASS_DISABLE_SCROLL);
      $modal.classList.remove(CLASS_HIDDEN);
      $modal.classList.add(CLASS_FADE_IN);
    }
  });

  $closeBtn.addEventListener("click", () => {
    if (state == 0) {
      state = 2;
      $modal.classList.add(CLASS_FADE_OUT);
      document.body.classList.remove(CLASS_DISABLE_SCROLL);
    }
  });

  $modal.addEventListener("animationend", () => {
    if (state == 1) {
      $modal.classList.remove(CLASS_FADE_IN);
      state = 0;
    } else if (state == 2) {
      $modal.classList.add(CLASS_HIDDEN);
      $modal.classList.remove(CLASS_FADE_OUT);
      state = 0;
    }
  });

  $form.addEventListener("submit", () => {
    alert("ありがとうございます。\n送信が完了しました。");
  });
})();
// ========================

// ========================
// burgerMenu
(() => {
  const $burgerMenu = document.getElementById("js-burgerMenu");
  const $burgerBtn = document.getElementById("js-burgerBtn");
  const class_click = "is-clicked";
  let state = 0; // 0:not processing, 1:opening. 2: closing

  // toggleBurger
  const toggleBurger = () => {
    if (state == 0) {
      if ($burgerMenu.classList.contains(CLASS_HIDDEN)) {
        state = 1;
        $burgerMenu.classList.remove(CLASS_HIDDEN);
        $burgerBtn.classList.add(class_click);
        document.body.classList.add(CLASS_DISABLE_SCROLL);
        $burgerMenu.classList.add(CLASS_FADE_IN);
      } else {
        state = 2;
        $burgerBtn.classList.remove(class_click);
        document.body.classList.remove(CLASS_DISABLE_SCROLL);
        $burgerMenu.classList.add(CLASS_FADE_OUT);
      }
    }
  };
  $burgerMenu.addEventListener("animationend", () => {
    if (state == 1) {
      $burgerMenu.classList.remove(CLASS_FADE_IN);
      state = 0;
    } else if (state == 2) {
      $burgerMenu.classList.remove(CLASS_FADE_OUT);
      $burgerMenu.classList.add(CLASS_HIDDEN);
      state = 0;
    }
  });

  $burgerBtn.addEventListener("click", () => {
    toggleBurger();
  });
})();
// ========================
